import styles from "././MoiHeader.styles.scss";
import moiLogoEn from "./moi-logo-en.png";
import moiLogoFr from "./moi-logo-fr.png";
import useTranslation from "../../../../../hooks/useTranslation";

const logo = {
  en: moiLogoEn,
  fr: moiLogoFr,
}

const getLogo = (lang: 'en' | 'fr') => {
  if (logo[lang]) {
    return logo[lang];
  }
  return logo['en']
}

function MoiHeader() {
  const {locale} = useTranslation()

  return (
    <header className={styles.header}>
      <img src={getLogo(locale as any)} alt="" />
    </header>
  );
}

export default MoiHeader;
