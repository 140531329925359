import {useSettingsContext} from "../../contexts/SettingsContext";
import MoiNotFound from "./publishers/metro/moiProgram/not-found/MoiNotFound";
import {useRouteError} from "react-router-dom";

function StandAloneNotFound() {
  const {appSlug} = useSettingsContext()
  const error = useRouteError()

  if (appSlug == 'programmemoi') {
    return <MoiNotFound />
  }

  throw error
}

export default StandAloneNotFound;
