import {Fragment, FunctionalComponent, h} from 'preact';
import DiscountCode from "../DiscountCode";
import Button from "../Button";
import PrivacyPolicy from "../PrivacyPolicy";
import {Reward} from "../../interfaces/sdk";
import useAppPhrases from "../../hooks/useAppPhrases";
import {useTranslation} from "../../contexts/I18nContext";
import useAuth from "../../hooks/useAuth";
import {OnClaimClicked, PrivacyTextData} from "./claimCta.types";
import NamedIcon from "../icons/NamedIcon";
import IconButton from "../icons/IconButton";
import {useState} from "preact/hooks";
import {queries} from "../../queryKeyFactory";
import {useQueryClient} from "@tanstack/react-query";
import {useSettingsContext} from "../../contexts/SettingsContext";
import {useInContentLayoutContext} from "../layouts/InContentLayout/InContentLayout";
import { Form } from 'react-bootstrap';

interface Props {
  reward: Reward
  textData: PrivacyTextData
  claimLoading: boolean;
  onClaimClicked: OnClaimClicked
}

const Claimable: FunctionalComponent<Props> = ({reward, textData, claimLoading, onClaimClicked}) => {
  const tAppPhrases = useAppPhrases({
    allowMissing: true,
    onMissingKey: () => {
      return ''
    }
  })
  const {t} = useTranslation()
  const {email, signIn} = useAuth()
  const queryClient = useQueryClient()
  const {allRewardsLimit, storeFrontType, isStandaloneApp, appSlug, application} = useSettingsContext()
  const {childApi} = useInContentLayoutContext()
  const [isLoadingAnonymousUser, setIsLoadingAnonymousUser] = useState(false)
  const [isChecked, setIsChecked] = useState(false);

  const {show_claim_consent, claim_btn_text, claim_email_disabled} = reward

  let privacyText = ''
  let consentSendToEmailOptional = false;
  if (show_claim_consent) {
    privacyText = tAppPhrases('consent_claim_shared_to_brand', textData) || t('privacyPolicy.claim-shared-to-brand', textData)
  } else if (!claim_email_disabled) {
    privacyText = tAppPhrases('consent_claim_send_to_email', textData)
    consentSendToEmailOptional = Boolean((application.features?.consent_send_to_email_optional ?? false) && privacyText);
  }

  const checkboxPrivacyEnabled = Boolean(application.features?.privacy_policy_checkbox && privacyText)

  const onCloseClick = async () => {
    setIsLoadingAnonymousUser(true)
    const user = await signIn({email: '', fallbackToAnonymousWhenFail: true})
    setIsLoadingAnonymousUser(false)
    if (storeFrontType === 'overlay') {
      if (childApi) {
        childApi.emit('set-user', user)
        childApi.emit('reload-rewards', reward.reward_id)
      }
    } else {
      await queryClient.fetchQuery({
        ...queries.rewards.list({
          list_type: 'claimable',
          reward_id: reward.reward_id,
          limit: allRewardsLimit
        }),
      })
    }
  }

  const handleClaimClicked = () => {
    onClaimClicked(
        '',
        (checkboxPrivacyEnabled && !isChecked) ? '' : privacyText,
    )
  }

  return (
      <Fragment>
        <DiscountCode codeType={reward.code_type} redact />
        <Button
            fullWidth
            secondary={appSlug !== 'programmemoi'}
            className="mb-2"
            onClick={handleClaimClicked}
            isLoading={claimLoading}
            disabled={checkboxPrivacyEnabled && !isChecked && !consentSendToEmailOptional}
        >
          {claim_btn_text || t('reward.btn.claim')}
        </Button>
        {!claim_email_disabled && (
            <div className="body3 d-inline-flex align-items-center mt-2">
              <span>{t('common.email')}:&nbsp;</span>
              <em>{email}</em>
              {!isStandaloneApp &&
                <>
                  {!isLoadingAnonymousUser ? (
                      <>
                        {!claimLoading &&
                          <IconButton onClick={() => onCloseClick()}>
                            <NamedIcon iconName="edit" className="ms-2" />
                          </IconButton>
                        }
                      </>
                    ) : (
                      <div class="spinner-border text-secondary spinner-border-sm ms-2" role="status">
                        <span class="visually-hidden">Loading...</span>
                      </div>
                  )}
                </>
              }
            </div>
        )}
        {checkboxPrivacyEnabled ? (
          <div className="text-start">
            <Form.Check
              type="checkbox"
              id="accept-send-email"
              label={<PrivacyPolicy text={privacyText} />}
              checked={isChecked}
              onChange={(event: any) => setIsChecked(event.target.checked)}
            />
          </div>
        ) : (
          <PrivacyPolicy text={privacyText} />
        )}
      </Fragment>
  )
};

export default Claimable;

