import {FunctionalComponent} from 'preact';
import ReportBugModal from "../../components/standalone/ReportBugModal";
import {useSettingsContext} from "../../contexts/SettingsContext";

const ReportBugRoute: FunctionalComponent = (props) => {
  const {application} = useSettingsContext()

  return (
    <>
      {application.features?.report_a_bug &&
        <ReportBugModal />
      }
    </>
  );
};

export default ReportBugRoute;

