import Polyglot, {PolyglotOptions} from 'node-polyglot'
import { createContext } from 'preact/compat'
import { useContext, useEffect, useMemo, useState } from 'preact/hooks'
import { ComponentChildren } from 'preact'
import en from '../i18n/en.json'
import fr from '../i18n/fr.json'
import { getBrowserLanguages } from '../utils'
import { setAuthAxiosLocaleHeader } from '../services/auth'
import { safeLocalStorage } from '../utils/storage'
import {useSettingsContext} from "./SettingsContext";
import {produce} from "immer";


/****
 * These type helpers allow us to generage a union type of all valid translation keys directly
 * from the imported babel json file. Code is adapted from this stack overflow post:
 * https://stackoverflow.com/questions/58434389/typescript-deep-keyof-of-a-nested-object
 */
type Join<K, P> = K extends string | number ?
    P extends string | number ?
    `${K}${"" extends P ? "" : "."}${P}`
    : never : never;

type Prev = [never, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10,
      11, 12, 13, 14, 15, 16, 17, 18, 19, 20, ...0[]]

type Leaves<T, D extends number = 10> = [D] extends [never] ? never : T extends object ?
    { [K in keyof T]-?: Join<K, Leaves<T[K], Prev[D]>> }[keyof T] : "";
/*********/

// Union type of all valid translation keys.
export type TranslationKey = Leaves<typeof en>

type PhraseItem = {
  [key: string]: PhraseItem | string;
}

type I18nProviderProps = {
  children: ComponentChildren | ((lang: string) => ComponentChildren);
}

type TranslationFn<T extends string = string> = ((phrase: T, options?: number | Polyglot.InterpolationOptions | undefined) => string) & {
  _polyglot: Polyglot;
}

type I18nContextValue = {
  t: TranslationFn<TranslationKey>;
  locale: string;
  setLocale: (locale: string) => void;
  setLocaleSupport: (langs: string[], defaultLang: string) => void;
  region: string;
  setRegion: (region: string) => void;
}

const initializePhraseData = () => {
  const data = {
    en,
    'en-caon': produce(en, draft => {
      draft.moiProgram.name = 'Moi Rewards'
      draft.moiProgram.site = 'moirewards.ca'
      draft.moiProgram.reconnection.signin = 'Sign in at moirewards.ca to gain access to these exclusive offers and discounts from the brands I love'
      draft.moiProgram.reconnection.signInHref = 'https://www.programmemoi.ca/en/more-for-moi'
      draft.moiProgram.reconnection.backLink = 'back to moirewards.ca'
      draft.moiProgram.notFound.body = "Sign in at moirewards.ca to gain access to these exclusive offers and discounts from the brands I love"
    }),
    'en-canb': {},
    'en-caqc': produce(en, draft => {

    }),
    fr,
    'fr-caon': produce(fr, draft => {

    }),
    'fr-canb': {},
    'fr-caqc': produce(fr, draft => {

    }),
  }

  data['en-canb'] = {...data['en-caon']}
  data['fr-canb'] = {...data['fr-caon']}

  return data
}

const phraseData = initializePhraseData()

const I18nContext = createContext<I18nContextValue | undefined>(undefined)

const useLocale = (languages: string[], fallbackLanguage = '') => {
  const {application} = useSettingsContext()
  const [locale, setLocale] = useState(fallbackLanguage)
  const [region, setRegion] = useState('')
  const [supported, setSupported] = useState({
    languages,
    defaultLanguage: fallbackLanguage
  })

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    const lang = params.get('language') || params.get('lang')
    const region = params.get('region') || safeLocalStorage.getItem('region') || ''
    const appMainLanguage = application.languages.length === 1 ? application.languages[0] : ''
    const localStorageLang = safeLocalStorage.getItem('lang') || ''

    // Establish language fallback priority
    const priority = [
      lang,
      application.languages.includes(localStorageLang) ? localStorageLang : '',
      appMainLanguage,
      ...getBrowserLanguages(),
      supported.defaultLanguage || 'en'
    ]
    const result = priority.find((v) => v && supported.languages.includes(v))

    setLocale(result || fallbackLanguage)
    setRegion(region || '')
  }, [supported, fallbackLanguage, application.languages])

  useEffect(() => {
    if (locale) {
      safeLocalStorage.setItem('lang', locale)
    }
    if (region) {
      safeLocalStorage.setItem('region', region)
    }
  }, [locale, region])

  const setLocaleSupport = useMemo(
    () => (languages: string[], defaultLanguage: string) =>
      setSupported({ languages, defaultLanguage }),
    [setSupported],
  )

  useEffect(() => {
    if (application) {
      setLocaleSupport(application.languages, '')
    }
  }, [application, setLocaleSupport])

  return {
    locale,
    setLocale,
    setLocaleSupport,
    region,
    setRegion,
  }
}

const getLocale = (language:string, region: string, phrases: any) => {
  const locale = region ? `${language}-${region}` : language;
  const DEFAULT_LANGUAGE = 'en'

  if (!locale) return DEFAULT_LANGUAGE

  // @ts-ignore
  if (phrases[locale]) {
    return locale;
  }

  // Fallback logic
  // @ts-ignore
  if (phrases[language]) {
    return language;
  }

  console.warn(`Locale "${locale}" not supported.  Falling back to en.`);
  return DEFAULT_LANGUAGE;
}

export const usePolyglot = <K extends string = string,>(
  phraseData: PhraseItem,
  lang: string,
  region: string,
  options: PolyglotOptions = {}
): TranslationFn<K> => {
  const t = useMemo(() => {
    const locale = getLocale(lang, region, phraseData)
    const polyglot = new Polyglot({
      phrases: phraseData[locale],
      locale,
      interpolation: {
        prefix: '{{',
        suffix: '}}',
      },
      ...options
    })
    const t = polyglot.t.bind(polyglot) as typeof polyglot.t
    ;(t as TranslationFn)._polyglot = polyglot

    return t as TranslationFn<K>
  }, [phraseData, lang, region, options])

  return t
}

export const I18nProvider = ({ children }: I18nProviderProps) => {
  const {application} = useSettingsContext()
  const { locale, setLocale, setLocaleSupport, region, setRegion } = useLocale(application.languages, '')

  const t = usePolyglot<TranslationKey>(phraseData, locale, region)

  useEffect(() => {
    setAuthAxiosLocaleHeader(locale, region)
  }, [locale, region])

  return (
    <I18nContext.Provider
      value={{
        t,
        locale,
        setLocale,
        setLocaleSupport,
        region,
        setRegion,
      }}
    >
      {typeof children === 'function' ? children(locale) : children}
    </I18nContext.Provider>
  )
}

export const useTranslation = () => {
  const context = useContext(I18nContext)
  if (context === undefined) {
    throw new Error('I18nContext must be used within an I18nProvider')
  }
  return context
}
