import CardPlaceholder from "../common/CardPlaceholder";
import Repeat from "../Repeat";
import {FunctionalComponent} from "preact";
import RewardsList from "../RewardsList";
import {useEffect} from 'preact/hooks'
import {useRewardsListEvents} from '../../hooks/useRewardsListEvents'
import {useSettingsContext} from "../../contexts/SettingsContext";
import useUserProfileQuery from "../../hooks/queries/useUserProfileQuery";
import {useNavigate} from "react-router-dom";
import WelcomeMessage from "../WelcomeMessage";
import useRewardsQuery from "../../hooks/queries/useRewardsQuery";
import * as panelRoutes from '../../appRouter/routesPaths/panel'
import * as standaloneRoutes from '../../appRouter/routesPaths/standalone'
import NoClaimableRewards from "../common/NoClaimableRewards";
import useHomePageTitle from "../../hooks/useHomePageTitle";
import usePhrases from "../../hooks/usePhrases";
import {Reward} from "../../interfaces/sdk";
import useRewardsClaimLimitReached from "../../hooks/useRewardsClaimLimitReached";
import AffiliateDisclosure from "../AffiliateDisclosure";
import PoweredByGoloot from "../PoweredByGoloot";
import SelectLocaleDropdown from "../common/SelectLocaleDropdown";

const getClaimId = (rewardList: Reward[], parentRewardId:string) => {
  const reward = rewardList.find(r => r.reward_id === parentRewardId)
  return reward?.claimed?.[0]?.claim_id || ''
}

const RewardsSection: FunctionalComponent = () => {
  const {
    initialParentRewardId,
    setInitialParentRewardId,
    directClaim, setDirectClaim,
    integrationType, appSlug, context, application} = useSettingsContext()
  const tPhrases = usePhrases()
  const {data, isLoading} = useRewardsQuery()
  const rewards = useRewardsClaimLimitReached(data?.data.payload || [])
  const {progress} = useUserProfileQuery()
  const navigate = useNavigate()
  const headerTitle = useHomePageTitle()
  const subheading = tPhrases('subheading')

  const requestId = data?.requestId
  const [rewardsRef] = useRewardsListEvents(false, false, rewards, requestId)

  useEffect(() => {
    if (directClaim && requestId) {
      const claimId = getClaimId(rewards, directClaim)
      setDirectClaim('')
      const reward = rewards.find(reward => reward.reward_id === directClaim)

      switch (integrationType) {
        case 'panel':
          if (reward) {
            navigate(`${panelRoutes.rewardSingle({
              id: reward.id,
              requestId: '',
              claimId,
            })}?autoClaim=${true}`)
          }
          break
        default:
          if (reward) {
            navigate(`${standaloneRoutes.rewardSingle({
              rewardId: reward.id,
              appSlug,
              context,
              claimId
            })}?autoClaim=${true}`)
          }
          break
      }
    } else if (initialParentRewardId && requestId) {
      const claimId = getClaimId(rewards, initialParentRewardId)
      setInitialParentRewardId('')
      const reward = rewards.find(reward => reward.reward_id === initialParentRewardId)


      switch (integrationType) {
        case 'panel':
          if (reward) {
            navigate(panelRoutes.rewardSingle({
              id: reward.id,
              requestId: '',
              claimId
            }))
          }
          break
        default:
          if (reward) {
            navigate(standaloneRoutes.rewardSingle({
              rewardId: reward.id,
              appSlug,
              context,
              claimId
            }))
          }
          break
      }
    }
  }, [initialParentRewardId, setInitialParentRewardId, requestId, directClaim, setDirectClaim, integrationType, navigate, appSlug, context, rewards])

  if (!progress) return null

  return (
      <div className="pt-2 pb-4">
        {rewards.length > 0 &&
          <div className="mb-4 text-center">
            <WelcomeMessage headerTitle={headerTitle} subheading={subheading} />
            <AffiliateDisclosure rewardsAmount={rewards.length} />
          </div>
        }
        {application.features?.locale_dropdown &&
          <div className="text-center mb-3">
            <SelectLocaleDropdown />
          </div>
        }
        <div className="row justify-content-center gy-3" ref={rewardsRef}>
          {isLoading && (
              <Repeat numTimes={4}>
                {(index: number) => (
                    <div key={index} className="col-12 col-md-auto">
                      <CardPlaceholder />
                    </div>
                )}
              </Repeat>
          )}

          {rewards.length > 0 &&
            <RewardsList
              rewards={rewards}
              requestId={requestId}
            />
          }
          {!isLoading && rewards.length === 0 &&
            <NoClaimableRewards />
          }

          <PoweredByGoloot rewardsAmount={rewards.length} />

        </div>
      </div>
  )
}

export default RewardsSection
