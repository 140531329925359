import { h, FunctionalComponent } from 'preact'

// @ts-ignore
import { Helmet } from 'react-helmet'
import { useTranslation } from '../../contexts/I18nContext'
import {useSettingsContext} from "../../contexts/SettingsContext";
import {useEffect} from "preact/hooks";

const PageMeta: FunctionalComponent = () => {
  const { t } = useTranslation()
  const {application, appSlug} = useSettingsContext()

  useEffect(() => {
    const favIconElem = document.querySelector('link[rel="shortcut icon"]')
    favIconElem?.setAttribute('href', `${application.icon}?t=${Date.now()}`)
  }, [application.icon])

  return (
    <Helmet>
      {appSlug === 'programmemoi' ? (
        <title>{t('moiProgram.meta.title')}</title>
      ) : (
        <title>{t('standalone.meta.titleNamed', { name: application.name })}</title>
      )}
    </Helmet>
  )
}
export default PageMeta
