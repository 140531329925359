import {usePolyglot, useTranslation} from "../contexts/I18nContext"
import {useSettingsContext} from "../contexts/SettingsContext";
import {IntegrationPhraseKey} from "../interfaces/phrases";


const usePhrases = () => {
  const {locale, region} = useTranslation()
  const {activeIntegration, application, isStandaloneApp} = useSettingsContext()

  const phrases = isStandaloneApp ? application.phrases : activeIntegration?.phrases

  return usePolyglot<IntegrationPhraseKey>(phrases ?? {}, locale, region, {
    allowMissing: false,
    // onMissingKey: (key, options, locale) => {
    //   return ''
    // },
  })
}

export default usePhrases
