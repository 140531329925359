import SweetAlert from 'sweetalert2/dist/sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'
import '../styles/sweet-alert-overwrite.css'
import {FunctionalComponent} from 'preact';
import RewardSingle from "./RewardSingle";
import {useState} from "preact/hooks";
import {useNavigate} from "react-router-dom";
import {createPortal} from "preact/compat";
import useEvents from "../hooks/useEvents";
import * as standaloneRoutes from '../appRouter/routesPaths/standalone'
import {useSettingsContext} from "../contexts/SettingsContext";
import {useDidMount, useWillUnmount} from "rooks";


const RewardSingleModal: FunctionalComponent = (props) => {
  const {context} = useSettingsContext()
  const {appSlug} = useSettingsContext()
  const [swalShown, setSwalShown] = useState(false)
  const navigate = useNavigate()
  const [isClaiming, setIsClaiming] = useState(false)
  const raiseEvent = useEvents()

  useDidMount(() => {
    const showSwal = () => {
      SweetAlert.fire({
        didOpen: () => setSwalShown(true),
        didClose: () => setSwalShown(false),
        showConfirmButton: false,
        width: 728,
        showClass: {
          popup: 'animate__animated animate__slideInUp animate__faster',
        },
        hideClass: {
          popup: ''
        },
        didDestroy() {
          navigate(standaloneRoutes.home({appSlug, context}))
        },
      }).then((result: any) => {
        raiseEvent({name: 'storefront-overlay-close'})
        // if (result.dismiss === SweetAlert.DismissReason.timer) {
        //   console.log("I was closed by the timer");
        // }
      })
    }
    showSwal()
  })

  useWillUnmount(() => {
    SweetAlert.close()
  })

  return (
      <>
        {swalShown && createPortal((
            <RewardSingle
                onClaimStart={() => setIsClaiming(true)}
                onClaimEnd={() => setIsClaiming(false)}
                childApi={null}
                onCloseClick={() => SweetAlert.close()}
            />
        ), SweetAlert.getHtmlContainer())}
      </>
  );
};

export default RewardSingleModal;

