import { usePolyglot, useTranslation } from "../contexts/I18nContext"
import {useSettingsContext} from "../contexts/SettingsContext";
import {IntegrationPhraseKey} from "../interfaces/phrases";


const useIntegrationPhrases = () => {
  const { locale, region } = useTranslation()
  const {activeIntegration} = useSettingsContext()

  return usePolyglot<IntegrationPhraseKey>(activeIntegration?.phrases ?? {}, locale, region, {
    allowMissing: true,
    onMissingKey: (key, options, locale) => {
      return ''
    },
  })
}

export default useIntegrationPhrases
