import {FunctionalComponent} from 'preact';
import RewardsSection from "../../components/sections/RewardsSection";
import {Outlet} from "react-router-dom";

const StandAloneHomeRoute: FunctionalComponent = () => {
  return (
      <div className="container">
        <RewardsSection />
        <Outlet />
      </div>
  )
};

export default StandAloneHomeRoute;

