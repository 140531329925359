import {queries} from "../../queryKeyFactory";
import {useCallback} from "preact/hooks";
import {useQueryClient} from "@tanstack/react-query";
import {useSettingsContext} from "../../contexts/SettingsContext";

const useInvalidateRewards = () => {
  const queryClient = useQueryClient()
  const {initialParentRewardId, allRewardsLimit, rewardListType} = useSettingsContext()

  return useCallback(() => {
    return queryClient.invalidateQueries({
      queryKey: queries.rewards.list({
        list_type: rewardListType,
        reward_id: initialParentRewardId,
        limit: allRewardsLimit
      }).queryKey,
      type: 'all',
    })
  }, [allRewardsLimit, initialParentRewardId, queryClient, rewardListType])
}

export default useInvalidateRewards
