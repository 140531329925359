import {createQueryKeyStore} from '@lukemorales/query-key-factory'
import {fetchRewards} from "./services/rewards";
import {fetchUserProgress} from "./services/auth";
import {RewardListType} from "./interfaces/sdk";

type RewardFilters = {
  list_type: RewardListType
  limit: number
  reward_id?: string
}

export const queries = createQueryKeyStore({
  rewards: {
    list: (filters: RewardFilters) => {
      const requestParams = {...filters}
      delete filters.reward_id
      return {
        queryKey: [{filters}],
        queryFn: (ctx) => fetchRewards(requestParams),
      }
    },
  },
  profile: {
    user: {
      queryKey: ['progress'],
      queryFn: fetchUserProgress,
    }
  }
});
