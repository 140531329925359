import {FunctionalComponent} from "preact";
import RewardsSection from '../../components/sections/RewardsSection'

const PanelHomeRoute: FunctionalComponent = () => {
  return (
      <>
        <div className="container">
          <RewardsSection />
        </div>
      </>
  )
}
export default PanelHomeRoute
