import { usePolyglot, useTranslation } from "../contexts/I18nContext"
import {useSettingsContext} from "../contexts/SettingsContext";

export type LocaleKey = 'privacy_policy_url';

const useAppLocale = () => {
  const { locale, region } = useTranslation()
  const {application} = useSettingsContext()
  return usePolyglot<LocaleKey>(application.locale ?? {}, locale, region, {
    allowMissing: true,
    onMissingKey: (key, options, locale) => {
      switch (key) {
        case 'privacy_policy_url':
          return application[key]
        default:
          console.error(`Missing value for key "${key}"`)
          return key
      }
    },
  })
}

export default useAppLocale
