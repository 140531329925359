import {usePolyglot, useTranslation} from "../contexts/I18nContext"
import {useSettingsContext} from "../contexts/SettingsContext";
import {ApplicationPhraseKey} from "../interfaces/phrases";
import {PolyglotOptions} from "node-polyglot";

const useAppPhrases = (options: PolyglotOptions = {}) => {
  const {locale, region} = useTranslation()
  const {application} = useSettingsContext()
  const tPhrase = usePolyglot<ApplicationPhraseKey>(application.phrases ?? {}, locale, region, options)
  return tPhrase
}

export default useAppPhrases
