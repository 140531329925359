import {FunctionalComponent, h} from 'preact';
import {GoogleReCaptchaProvider} from "react-google-recaptcha-v3";
import useTranslation from "../hooks/useTranslation";
import {useSettingsContext} from "./SettingsContext";


const GoogleRecaptchaWithAppSettings: FunctionalComponent = ({children}) => {
  const {locale} = useTranslation()
  const {application} = useSettingsContext()

  return (
      <>
        {(application.recaptcha_login_enabled || application.features?.report_a_bug) ? (
          <GoogleReCaptchaProvider
            reCaptchaKey={process.env.PREACT_APP_RECAPTCHA_KEY || ''}
            language={locale}
            useRecaptchaNet={false}
            useEnterprise
            scriptProps={{
              async: false,
              defer: false,
              appendTo: 'head',
              nonce: undefined
            }}
          >
            {children}
          </GoogleReCaptchaProvider>
        ) : (
          <>{children}</>
        )}
      </>
  );
};

export default GoogleRecaptchaWithAppSettings;

