import {useQuery} from "@tanstack/react-query";
import {queries} from "../../queryKeyFactory";
import {useSettingsContext} from "../../contexts/SettingsContext";

const useRewardsQuery = () => {
  const {initialParentRewardId, allRewardsLimit, rewardListType} = useSettingsContext()
  return useQuery({
    refetchOnMount: false,
    staleTime: 5000 * 60,
    ...queries.rewards.list({
      list_type: rewardListType,
      reward_id: initialParentRewardId,
      limit: allRewardsLimit
    }),
  })
}

export default useRewardsQuery
