import styles from './MoiNotFound.styles.scss'
import notFoundImg from './not-found-img.png'
import MoiHeader from "../MoiHeader";
import useTranslation from "../../../../../../hooks/useTranslation";

function MoiNotFound() {
  const {t, locale} = useTranslation()

  return (
    <div className={styles.moiNotFound}>
      <MoiHeader />
      <div class="container py-4">
        <div class="row">
          <div class="col-12 col-md-4 text-center">
            <img style={{width: 320, borderRadius: '50%'}} src={notFoundImg} alt="" />
          </div>
          <div class="col-12 col-md-8 d-flex flex-column gap-4 text-center text-md-start align-items-center align-items-md-start">
            <div><strong>{t('moiProgram.notFound.404')}</strong></div>
            <div className={styles.title}>{t('moiProgram.notFound.lost')}</div>
            <div dangerouslySetInnerHTML={{__html: t('moiProgram.notFound.body')}} />
            <a href={`https://www.programmemoi.ca/${locale}`} className={styles.moiBtn}>{t('moiProgram.notFound.visitBtn')}</a>
          </div>
        </div>
      </div>

    </div>
  );
}

export default MoiNotFound;
