import {FunctionalComponent} from 'preact'
import styles from './NavBar.style.scss'
import IconButton from './icons/IconButton'
import useEvents from '../hooks/useEvents'
import BackIcon from './icons/BackIcon'
import {useSettingsContext} from "../contexts/SettingsContext";
import * as panelRoutes from "../appRouter/routesPaths/panel";
import {useLocation, useMatch, useNavigate} from "react-router-dom";
import NamedIcon from "./icons/NamedIcon";

type Props = {
  childApi: ChildAPI | undefined
}

const NavBar: FunctionalComponent<Props> = ({childApi}) => {
  const {application} = useSettingsContext()
  const raiseEvent = useEvents()
  const navigate = useNavigate()
  const {pathname} = useLocation()
  const homeMatch = useMatch(panelRoutes.home());

  const onCloseClick = () => {
    childApi && childApi.emit('close', {})
  }

  const handleBackClick = () => {
    const match = /^\/?(\w+)\/?/.exec(pathname)
    const page = (match && match[1]) || ''
    raiseEvent({name: 'panel-back', page, path: pathname})
    navigate(-1) //important to use -1 so that scroll restoration works properly
  }

  return (
      <nav className={styles.navbar}>
        {!homeMatch && (
            <IconButton onClick={handleBackClick} aria-label="Back">
              <BackIcon width={32} height={32} />
            </IconButton>
        )}

        {Boolean(application.logo) &&
          <img src={application.logo} height={36} alt={`${application.name} logo`} />
        }

        <IconButton className="ms-auto" onClick={onCloseClick} aria-label="User">
          <NamedIcon iconName="panelClose" width={32} height={32} />
        </IconButton>
      </nav>
  )
}

export default NavBar

