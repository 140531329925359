import { FunctionalComponent, h } from 'preact'
import { useTranslation } from '../../contexts/I18nContext'
import { AppInfo } from '../../interfaces/publishers'
import styles from './Footer.styles.scss'
import {Link} from "react-router-dom";
import BugIcon from "../icons/BugIcon";
import {useSettingsContext} from "../../contexts/SettingsContext";
import Button from "../Button";
import {lowerFirst} from "lodash";

export interface FooterProps {
  appInfo: Pick<AppInfo, 'name' | 'app_website_url' | 'logo'>
}

export type FooterComponent = FunctionalComponent<FooterProps>

const Footer: FooterComponent = ({ appInfo }) => {
  const { t } = useTranslation()
  const { appSlug, application } = useSettingsContext()

  return (
    <footer className={styles.footer}>
      {appInfo.app_website_url && appInfo.name && (
        <div>
          {appSlug === 'programmemoi' ? (
            <Button
              href={appInfo.app_website_url}
              className="d-inline-flex w-auto px-4"
              onClick={() => {
                window.location.href = appInfo.app_website_url
              }}
            >
              {lowerFirst(t('moiProgram.reconnection.backLink', {name: t('moiProgram.name')}))}
            </Button>
          ) : (
            <a href={appInfo.app_website_url}>{t('standalone.backLink', { name: appInfo.name })}</a>
          )}

        </div>
      )}
      {application.features?.report_a_bug &&
        <div className="my-2 py-4">
          <Link to="rewards/report-bug" className="d-inline-flex align-items-center text-decoration-none gap-1">
            <BugIcon />
            {t('bugReport.title')}
          </Link>
        </div>
      }
    </footer>
  )
}

export default Footer
